import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DidomiSDK } from '@didomi/react';

const DidomiContext = React.createContext({});

const DidomiProvider = ({ children }) => {
    const [Didomi, setDidomi] = useState(null);
    const [crispActivated, setCrisp] = useState(false);
    const [hubspotActivated, setHubspot] = useState(false);
    const [adwordsActivated, setAdwords] = useState(false);
    const [gaActivated] = useState(true);

    const onReadyDidomi = (sentDidomi) => {
        setDidomi(sentDidomi);
    };

    const showPreferences = useCallback(() => {
        if (!Didomi) { return; }
        Didomi.preferences.show();
    }, [Didomi]);

    useEffect(() => {
        if (!Didomi) { return; }
        Didomi.getObservableOnUserConsentStatusForVendor(process.env.DIDOMI_CRISP_ID)
            .subscribe((consentStatus) => {
                setCrisp(() => consentStatus);

                if (consentStatus && window.$crisp) {
                    window.$crisp.push(['do', 'chat:show']);
                } else if (window.$crisp) {
                    window.$crisp.push(['do', 'chat:hide']);
                }
            });
        Didomi.getObservableOnUserConsentStatusForVendor(process.env.DIDOMI_HUBSPOT_ID)
            .subscribe((consentStatus) => {
                setHubspot(consentStatus);
            });
        Didomi.getObservableOnUserConsentStatusForVendor(process.env.DIDOMI_GOOGLEADS_ID)
            .subscribe((consentStatus) => {
                setAdwords(() => consentStatus);
            });
    }, [Didomi]);

    const values = useMemo(() => ({
        crispActivated,
        hubspotActivated,
        gaActivated,
        showPreferences,
        adwordsActivated,
    }), [adwordsActivated, crispActivated, gaActivated, hubspotActivated, showPreferences]);

    return (
        <DidomiContext.Provider value={values}>
            <DidomiSDK
                apiKey={process.env.DIDOMI_KEY}
                iabVersion={2}
                gdprAppliesGlobally
                onReady={onReadyDidomi}
            />
            {children}
        </DidomiContext.Provider>
    );
};

const useDidomiContext = () => useContext(DidomiContext);

DidomiProvider.propTypes = {
    children: PropTypes.node,
};

DidomiProvider.defaultProps = {
    children: undefined,
};

export { useDidomiContext, DidomiProvider };
